import store from '@/store';
import { ITranslations } from '../translations/index';
/**
 * frontend should handle errors like this

    check http code
    check api errors (first level )
    check errors in “_errors” key if available
 */

export const VALIDATION_ERRORS = 'VALIDATION_ERRORS';
export const VALIDATION_FIELD_ERRORS = 'VALIDATION_FIELD_ERRORS';
export const OTHER_ERRORS = 'OTHER_ERRORS';

type ResponseDataObject = { [key: string]: string };
type CustomError = { code: string, message: string };
type ResponseDataObjectCustomErrors = { _errors: CustomError[] };
type ResponseDataArray = string[];
type ResponseDataString = string;
type IErrorResponseData = ResponseDataObject | ResponseDataArray | ResponseDataString | ResponseDataObjectCustomErrors;

export function extractErrorMessages(errorResponseData: IErrorResponseData) {
  const translations: ITranslations = store.getters.getTranslations;
  const errors = {
    VALIDATION_ERRORS: '',
    FIELD_ERRORS: [{}],
    VALIDATION_FIELD_ERRORS: {},
    OTHER_ERRORS: [''],
  };

  try {
    if (Array.isArray(errorResponseData)) {
      // Validation errors: eg. ["someError"]
      const validationErrors = Object.values(errorResponseData)[0];
      (<ResponseDataString><unknown>errors[VALIDATION_ERRORS]) = validationErrors;
    } else if (typeof errorResponseData === 'object') {
      const keys = Object.keys(errorResponseData);

      if (keys.includes('_errors')) {
        // Custom error structure
        /*
          {
            "_errors": [
              {
                "code": "10005",
                "message": "Allowed registration capacity is exceeded"
              }
            ]
          }
         */
        /* eslint no-underscore-dangle: 0 */
        const customErrors = errorResponseData as ResponseDataObjectCustomErrors;
        errors[VALIDATION_ERRORS] = customErrors._errors[0].message;
      }
      if (keys.includes('detail')) {
        // {"detail": "You do not have permission to perform this action."}
        const detailError = errorResponseData as ResponseDataObject;
        errors[VALIDATION_ERRORS] = detailError.detail;
      } else {
        // Validation errors
        const validationErrors = {} as ResponseDataObject;

        keys.forEach((key) => {
          const data = errorResponseData as ResponseDataObject;
          let [message] = data[key];
          if (key === 'Error') {
            // Possible database errors
            // {"Error":["The fields attending, session must make a unique set."]}

            if (message.includes('unique set')) {
              message = translations.common.already_exists;
            }

            errors[VALIDATION_ERRORS] = message;
          } else {
            // Validation field errors
            // {"external_link":["Introduza um URL válido."]}
            validationErrors[key] = message;
          }
        });

        errors[VALIDATION_FIELD_ERRORS] = validationErrors;
      }
    } else {
      // Unexpected format
      errors[OTHER_ERRORS] = [translations.common.something_went_wrong];
    }
  } catch (error) {
    // Could not catch error
    errors[OTHER_ERRORS] = [translations.common.something_went_wrong];
  }
  return errors;
}

export default extractErrorMessages;
