import firstLetterUpperCase from '@/utils/firstLetterUppercase';
import formatDate from '@/utils/formatDate';
import getYoutubeId from '@/utils/getYoutubeId';
import hashString from '@/utils/hashString';
import isEmail from '@/utils/isEmail';
import isJson from '@/utils/isJson';
import isMobileDevice from '@/utils/isMobileDevice';
import isUrl from '@/utils/isUrl';
import modelToForm from '@/utils/modelToForm';
import sort from '@/utils/sort';
import urlExists from '@/utils/urlExists';
import shadeColor from '../utils/shadeColor';
import extractErrorMessages from '../utils/extractErrorMessages';
import { parseTimestamp } from '../utils/dates';

export default () => ({
  parseTimestamp,
  extractErrorMessages,
  firstLetterUpperCase,
  formatDate,
  getYoutubeId,
  hashString,
  isEmail,
  isJson,
  isMobileDevice,
  isUrl,
  modelToForm,
  shadeColor,
  sort,
  urlExists,
});
