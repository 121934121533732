var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{staticClass:"session-details",attrs:{"config":_vm.modal},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.session.name)?_c('h4',{staticClass:"title is-4"},[_vm._v(_vm._s(_vm.session.name))]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [(_vm.session && _vm.session.capacity !== 0)?_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-light",attrs:{"type":"button"},on:{"click":function($event){return _vm.modal.close()}}},[_vm._v(" "+_vm._s(_vm.translations.common.close)+" ")]),(
          _vm.sessionHasValidDates() &&
          _vm.session.registration_capacity !== 0 &&
          ((!_vm.state.registrationAvailable && _vm.state.registrationsLoaded) || _vm.userAdmin) &&
          (_vm.session.embedded || _vm.session.external_link)
        )?_c('button',{staticClass:"button is-success is-pulled-right",staticStyle:{"margin":"0"},attrs:{"type":"button"},on:{"click":function($event){return _vm.enterSession()}}},[_vm._v(" "+_vm._s(_vm.translations.exhibitors.sessions.join_meeting)+" ")]):_vm._e(),(
          !_vm.sessionHasValidDates() &&
          _vm.session.registration_capacity !== 0 &&
          ((!_vm.state.registrationAvailable && _vm.state.registrationsLoaded) || _vm.userAdmin) &&
          (_vm.session.embedded || _vm.session.external_link)
        )?_c('button',{staticClass:"button is-success is-pulled-right tooltip is-tooltip-top",staticStyle:{"margin":"0"},attrs:{"type":"button","data-tooltip":_vm.translations.visitors.sessions.join_in_15,"disabled":""}},[_vm._v(" "+_vm._s(_vm.translations.exhibitors.sessions.join_meeting)+" ")]):_vm._e(),(_vm.state.registration && _vm.state.registration.status === 5)?_c('button',{staticClass:"button is-success is-pulled-right",class:{ 'is-loading': _vm.state.loading },staticStyle:{"margin-right":"0.5rem"},attrs:{"type":"button","disabled":_vm.session.total_attendings >= _vm.session.registration_capacity},on:{"click":function($event){return _vm.acceptInvitation()}}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v(" check ")])]),_c('span',{staticStyle:{"vertical-align":"top"}},[_vm._v(_vm._s(_vm.translations.common.accept))])]):_vm._e(),(_vm.state.registration && _vm.state.registration.status === 5)?_c('button',{staticClass:"button is-danger is-pulled-right",class:{ 'is-loading': _vm.state.loading },staticStyle:{"margin-right":"0.5rem"},attrs:{"type":"button","disabled":_vm.session.total_attendings >= _vm.session.registration_capacity},on:{"click":function($event){return _vm.rejectInvitation()}}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v(" close ")])]),_c('span',{staticStyle:{"vertical-align":"top"}},[_vm._v(" "+_vm._s(_vm.translations.common.refuse))])]):_vm._e(),(
          (_vm.state.registrationAvailable || !_vm.contextVisitor.id) &&
          _vm.session.registration_capacity !== 0
        )?_c('button',{staticClass:"button is-aqualight is-pulled-right",class:{ 'is-loading': _vm.state.loading },staticStyle:{"margin-right":"0.5rem"},attrs:{"type":"button","disabled":_vm.session.total_attendings >= _vm.session.registration_capacity},on:{"click":function($event){return _vm.joinSession()}}},[_vm._v(" "+_vm._s(_vm.translations.exhibitors.sessions.register)+" ")]):_vm._e()]):_vm._e()]},proxy:true}])},[(_vm.session._image && !_vm.embedUrl)?_c('figure',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.session._image.file,"alt":_vm.session.name,"loading":"lazy"}})]):_vm._e(),(_vm.embedUrl && !_vm.embedUrl.includes('playback.live-video.net'))?_c('div',{staticClass:"iframe-container",staticStyle:{"margin-bottom":"0.5rem"}},[_c('iframe',{staticClass:"responsive-iframe",attrs:{"id":"session-iframe","allow":"camera; microphone; fullscreen; display-capture","src":_vm.embedUrl,"exactfit":"true","frameborder":"0","allowfullscreen":""}})]):_vm._e(),(_vm.embedUrl && _vm.embedUrl.includes('playback.live-video.net'))?_c('AWSStream',{attrs:{"streamUrl":_vm.embedUrl}}):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.translations.exhibitors.sessions.start_date)+" "),(_vm.session.start_date)?_c('b',[_vm._v(_vm._s(_vm.formatDate(_vm.session.start_date)))]):_vm._e()]),_c('p',[_vm._v(" "+_vm._s(_vm.translations.exhibitors.sessions.end_date)+" "),(_vm.session.end_date)?_c('b',[_vm._v(_vm._s(_vm.formatDate(_vm.session.end_date)))]):_vm._e()]),(_vm.session.exhibitor)?_c('p',[_vm._v(" "+_vm._s(_vm.translations.common.exhibitor)+": "),_c('a',{on:{"click":function($event){return _vm.openExhibitorPage(_vm.session.exhibitor)}}},[_c('b',[_vm._v(_vm._s(_vm.session.exhibitor_name))])])]):_vm._e(),(
      _vm.sessionHasValidDates() &&
      _vm.session.external_link &&
      !_vm.state.registrationAvailable &&
      _vm.state.registrationsLoaded
    )?_c('br'):_vm._e(),(
      _vm.sessionHasValidDates() &&
      _vm.session.external_link &&
      !_vm.state.registrationAvailable &&
      _vm.state.registrationsLoaded
    )?_c('p',[_vm._v(" "+_vm._s(_vm.translations.exhibitors.sessions.meeting_url)),_c('br'),(
        ((!_vm.state.registrationAvailable && _vm.state.registrationsLoaded) || _vm.userAdmin) &&
        (_vm.session.embedded || _vm.session.external_link)
      )?_c('a',{on:{"click":function($event){return _vm.enterSession()}}},[_vm._v(" "+_vm._s(_vm.session.external_link)+" ")]):_vm._e()]):_vm._e(),_c('br'),(_vm.session.description)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.translations.exhibitors.sessions.description))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.session.description)}}),_c('br')]):_vm._e(),(
      _vm.session.registration_capacity !== 0 &&
      _vm.session.total_attendings >= _vm.session.registration_capacity
    )?_c('div',{staticClass:"session-capacity"},[_c('small',{staticClass:"tag is-default"},[_vm._v(" "+_vm._s(_vm.translations.common.session_full)+" ")])]):_vm._e(),(
      _vm.session.registration_capacity !== 0 &&
      ((!_vm.state.registrationAvailable && _vm.state.registrationsLoaded) || _vm.userAdmin)
    )?_c('div',{staticClass:"tag is-success"},[_vm._v(" "+_vm._s(_vm.translations.common.registered)+" ")]):_vm._e(),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }