

















































































































































































import { computed, defineComponent, PropType, reactive } from '@vue/composition-api';
import { MutationPayload } from 'vuex';
import { ISession, ISessionRegistration } from '@/models/session';
import {
  getSessionRegistrations,
  sessionRegistrationCreate,
  sessionRegistrationUpdate,
} from '@/api/sessions';
import router from '@/router';
import useUtils from '@/composition/utils';
import usePopups from '@/composition/popups';
import { OTHER_ERRORS, VALIDATION_ERRORS } from '@/utils/extractErrorMessages';
import Modal from '@/components/modal.vue';
import AWSStream from '@/components/aws-stream.vue';
import store from '@/store';
import useContext, { IUseContext } from '@/composition/context';
// @ is an alias to /src
const staging = process.env.VUE_APP_SERVER_PATH === 'https://b.beamian.com/api';

export default defineComponent({
  components: { Modal, AWSStream },
  props: {
    session: {
      type: Object as PropType<ISession>,
      required: true,
    },
    originalRoute: {
      type: String,
      required: true,
    },
    isCompact: Boolean,
  },
  setup(props) {
    const { formatDate, extractErrorMessages } = useUtils();
    const { contextVisitor, translations } = useContext() as unknown as IUseContext;
    const { addPopup } = usePopups();

    const session = parseInt(router.currentRoute.params.session, 10);
    const event = parseInt(router.currentRoute.params.event, 10);
    const exhibitor = parseInt(router.currentRoute.params.exhibitor, 10);

    const embedUrl = computed(() => {
      const sessionUrl = props.session.streaming_link;
      return props.session.external_link && props.session.external_link !== '' ? sessionUrl : null;
    });

    const userAdmin = computed(() => <boolean>store.getters.userAdmin);

    const state = reactive({
      registrationsLoaded: false,
      registrationAvailable: false,
      loading: false,
      registration: {} as ISessionRegistration,
    });

    const modal = {
      isActive: true,
      isCard: true,
      close: () => {
        router.push(props.originalRoute);
      },
    };

    const sessionHasValidDates = () => {
      const currentDate = new Date();
      const sessionStartDate = new Date(props.session.start_date);
      const sessionEndDate = new Date(props.session.end_date);
      sessionStartDate.setMinutes(sessionStartDate.getMinutes() - 15);
      const sessionStarted = sessionStartDate <= currentDate;
      const sessionIsFinished = currentDate >= sessionEndDate;
      return staging || userAdmin.value || (sessionStarted && !sessionIsFinished);
    };

    const enterSession = () => {
      if (state.registration) {
        sessionRegistrationUpdate({ ...state.registration, status: 2 });
      }

      if (props.session.embedded) {
        router.push(`/event/${event}/session/${props.session.id}`);
      } else {
        window.open(props.session.external_link, '_blank');
      }
    };

    const joinSession = () => {
      if (contextVisitor.value.id) {
        state.loading = true;
        sessionRegistrationCreate({
          session,
          attending: contextVisitor.value.id,
        })
          .then((response) => {
            console.log(response);
            state.loading = false;
            state.registrationAvailable = false;
            state.registrationsLoaded = true;
            getSessionStatus();
          })
          .catch((err) => {
            state.loading = false;
            const errorData = extractErrorMessages(err.response.data);
            const popupErrors = [errorData[VALIDATION_ERRORS], ...errorData[OTHER_ERRORS]];

            for (const value of popupErrors) {
              addPopup({
                type: 'info',
                message: value,
                autohide: true,
              });
            }
          });
      } else {
        store.commit('openLoginPrompt');
      }
    };

    const acceptInvitation = async () => {
      state.loading = true;

      const updateSRResponse = await sessionRegistrationUpdate({
        id: state.registration.id,
        status: 6,
      });
      if (updateSRResponse) {
        state.loading = false;
      }
    };

    const rejectInvitation = async () => {
      state.loading = true;

      const updateSRResponse = await sessionRegistrationUpdate({
        id: state.registration.id,
        status: 6,
      });
      if (updateSRResponse) {
        state.loading = false;
      }
    };

    const openExhibitorPage = (exhibitorId: number) => {
      let exhibitorPage = `/event/${event}`;
      if (contextVisitor.value.id) {
        exhibitorPage += `/visitor/${contextVisitor.value.id}/manage-exhibitor/${exhibitorId}`;
      } else if (exhibitor) {
        exhibitorPage += `/event/${event}/manage-exhibitor/${exhibitor}/profile`;
      }
      window.open(`${exhibitorPage}`, '_blank');
    };

    const getSessionStatus = () => {
      if (contextVisitor.value.id !== 0) {
        getSessionRegistrations({
          session,
          visitor: contextVisitor.value.id,
        }).then(
          (response) => {
            state.registrationsLoaded = true;
            const registration = response.data.results.find(
              (registration) => registration.session === session,
            );
            if (registration) {
              state.registration = registration;
            }
            state.registrationAvailable = !registration;
          },
          (error) => {
            console.log(error);
            // Could not retrieve registration. Enable registration.
            state.registrationAvailable = true;
            state.registrationsLoaded = true;
          },
        );
      } else if (exhibitor) {
        state.registrationAvailable = false;
        state.registrationsLoaded = true;
      }
    };

    getSessionStatus();

    const unsubscribe = store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setContextVisitor' && mutation.payload.id) {
        getSessionStatus();
        unsubscribe();
      }
    });

    return {
      translations,
      contextVisitor,
      enterSession,
      formatDate,
      openExhibitorPage,
      acceptInvitation,
      rejectInvitation,
      joinSession,
      sessionHasValidDates,
      modal,
      state,
      userAdmin,
      embedUrl,
      event,
    };
  },
  created() {
    if (this.embedUrl && this.embedUrl !== '') {
      setTimeout(() => {
        const contextVisitor = store.getters.getContextVisitor;
        this.$gtag.event(`stream_entered_${this.session.id}`, {
          event_category: `event_${this.event}`,
          event_label: `visitor_${contextVisitor.id} `,
        });
      }, 3000);
      this.sessionInterval = setInterval(() => {
        const contextVisitor = store.getters.getContextVisitor;
        this.$gtag.event(`stream_watched_${this.session.id} `, {
          event_category: `event_${this.event}`,
          event_label: `visitor_${contextVisitor.id}`,
          value: 30,
        });
      }, 30000);
    }
  },
});
