import {
  computed,
} from '@vue/composition-api';
import store from '@/store';
import { IPopup } from '@/components/index';

// GETTERS

const popups = computed(() => store.getters.getPopups as IPopup[]);

// SETTERS

const addPopup = (popup: IPopup) => {
  store.commit('addPopup', popup);
};

const setPopups = (popups: IPopup[]) => {
  store.commit('setPopups', popups);
};

export default () => {

  return {
    popups,
    addPopup,
    setPopups,
  };
};
