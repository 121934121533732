



import { computed, ComputedRef, defineComponent, reactive } from '@vue/composition-api';
import { ISession } from '@/models/session';
import { getSessionById } from '@/api/sessions';
import router from '@/router';
import SessionDetails from '@/partials/SessionDetails.vue';
import { ITranslations } from '@/translations';
import store from '@/store';
import useContext from '@/composition/context';
import { IVisitor } from '@/models/visitor';
// @ is an alias to /src

export default defineComponent({
  components: {
    SessionDetails,
  },
  setup() {
    const translations = computed(() => store.getters.getTranslations as ITranslations);
    const { contextVisitor } = useContext() as { contextVisitor: ComputedRef<IVisitor> };
    const session = reactive({} as ISession);

    const sessionId = parseInt(router.currentRoute.params.session, 10);
    const event = parseInt(router.currentRoute.params.event, 10);
    const visitor = parseInt(router.currentRoute.params.visitor, 10);
    const exhibitor = parseInt(router.currentRoute.params.exhibitor, 10);

    let originalRoute = `/event/${event}/sessions`;
    const currentPath = router.currentRoute.path;
    if (currentPath.includes('session-details')) {
      originalRoute = `/event/${event}`;
    } else if (currentPath.includes('/exhibitor/')) {
      originalRoute = `/event/${event}/exhibitor/${exhibitor}`;
    }
    return {
      session,
      translations,
      sessionId,
      event,
      originalRoute,
      visitor,
      contextVisitor,
    };
  },
  created() {
    getSessionById(this.sessionId).then((response) => {
      if (response.data) {
        this.session = response.data;
        this.$forceUpdate();
        setTimeout(() => {
          this.$gtag.event(`session_details_${this.session.id}`, {
            event_category: `event_${this.event}`,
            event_label: `visitor_${this.contextVisitor.id}`,
          });
        }, 2000);
      }
    });
  },
});
